@import "../../scss/defaults";

// Custom Elements
@import "../../scss/form";
@import "../../scss/button";
@import "../../scss/section";
// @import "../../scss/header";
@import "../../scss/nav-row.scss";
@import "../../scss/footer";
@import "../../scss/images";

header {

  background: transparent linear-gradient(-45deg, #50CEFB calc(50% - 700px), #1E88E5 50%, #1565C0 calc(50% + 700px)) 0% 0% no-repeat;
  @include shadow-small;

  @include breakpoint(medium down) {
    background: transparent linear-gradient(-45deg, #50CEFB 0%, #1E88E5 50%, #1565C0 100%) 0% 0% no-repeat;
    @include shadow-none;
  }


  .header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 26px;


    @include breakpoint(medium down) {
      height: auto;
      justify-content: flex-start;
      padding-bottom: 18px;
    }
  }

}

.content {
  margin-bottom: $footer-height;

  @include breakpoint(medium down) {
    margin-bottom: 0;
  }
}

.animation {
  width: 100%;
  margin-top: -150px;
  position: relative;
  z-index: -1;
  min-height: 847px;

  @include breakpoint(medium down) {
    min-height: 0;
    margin-top: -15%;
  }
}

$transition: 1s;

.title {
  transform: translateX(calc(-100vw - 100%));
  transition: $transition ease-out;
  margin-top: -200px;

  @include breakpoint(medium down) {
    margin-top: -15%;
  }

  &.active {
    transform: none;
  }
}

.title2 {
  color: #FF9800;
}

.subtitle {
  font-family: "Maison Neue Light";
  text-align: center;
  padding: 0 140px;
  padding-bottom: 70px;
  line-height: 1.6em;

  transform: translateX(calc(-100vw - 100%));
  transition: $transition ease-out;
  transition-delay: 1.2s;

  a {
    font-family: "Maison Neue Demi";
    font-size: inherit;
  }

  @include breakpoint(medium down) {
    padding: 0;
  }

  &.active {
    transform: none;
  }
}


.es,
.en,
.ca {
  display: none;
}

:lang(es) .es,
:lang(en) .en,
:lang(ca) .ca {
  display: block;
}
